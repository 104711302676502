import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import AppTitleLogo from "../../assets/images/projam-icon.svg";
import API_BASE_URL from "../../components/config/appConfig";
import { registerSchema } from "../../Validations/schema";
import "./home.scss";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AddPeople } from "../../utilities/Json";
import SelectMenuTextField from "../../components/SelectMenuTextField";
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Register = () => {
  const LogoText = styled(Box)`
    font-weight: 700;
    letter-spacing: 1.4px;
    color: ##010101;
  `;

  const loginCardStyles = {
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    width: "30%",
    margin: "0 auto",
    padding: "20px",
  };

  const navigate = useNavigate();
  const [success, setSuccess] = useState('');
  const [registrationFailure, SetRegistrationFailure] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/signUp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json(); // Parse JSON response
      if (!response.ok) {
        // const data = await response.json();
        SetRegistrationFailure(data.message)
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        throw new Error(data.message || "Registration failed");
      } else {
        // const data = await response.json();
        setSuccess(data.message || "Registration successful!")
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        navigate("/login");
      }

    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/projects");
    }
  }, []);
  return (
    <Box className="register-page-cls">
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={success}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', width: '100px' }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '300px', display: 'flex', alignItems: 'center' }}
        >
          {snackbarSeverity === "success" ? success : registrationFailure}
        </Alert>
      </Snackbar>
      <Box
        sx={loginCardStyles}
        className="d-flex flex-column p-5 shadow login-card-styles"
      >
        <Typography
          noWrap
          className="text-center mb-1 d-flex align-items-center justify-content-center"
        >
          <img
            src={AppTitleLogo}
            alt="App-Title-Logo"
            height={44}
            className="me-1"
          />
          <LogoText fontSize={'20px !important'}>Projam Software</LogoText>
        </Typography>
        <Typography className="text-center mb-2 mt-0" sx={{ fontWeight: 600 }}>
          Register To Continue
        </Typography>
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            role: "",
            department: "",
          }}
          validationSchema={registerSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, error }) => (
            <Form>
              <Field name="name">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Name*"
                    size="small"
                    variant="outlined"
                    placeholder="Enter name"
                    fullWidth
                    className="mt-2"
                    InputProps={{
                      // endAdornment: <AccountCircleIcon />,
                      startAdornment: <AccountCircleIcon className="me-1" />,
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="name"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email*"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter email address"
                    className="mt-2"
                    InputProps={{
                      // endAdornment: <EmailIcon />,
                      startAdornment: <EmailIcon className="me-1" />,
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />

              <Field name="password">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Password*"
                    variant="outlined"
                    placeholder="Enter password"
                    size="small"
                    fullWidth
                    type="password"
                    className="mt-2"
                    InputProps={{
                      // endAdornment: <LockIcon />,
                      startAdornment: <LockIcon className="me-1" />
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="password"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />

              {/* <Field name="role" sx= {{marginTop: "3px"}}>
                {({ field }) => (
                  // <TextField
                  //   {...field}
                  //   label="Role"
                  //   variant="outlined"
                  //   size="small"
                  //   fullWidth
                  //   type="role"
                  //   className="mt-2"
                  // />
                  <div 
                  className="mt-2">
                  <SelectMenuTextField
                  {...field}
                    labelText="Role"
                    options={AddPeople}
                    onChange={(event) => setSelectedRole(event.target.value)}
                    name="role"
                  />
                  </div>
                )}
              </Field> */}
              {/* <Field name="role" sx={{ marginTop: "3px" }}>
                {({ field, form }) => (
                  <div className="mt-2">
                    <SelectMenuTextField
                      {...field}
                      labelText="Role*"
                      placeholder='Select role'
                      options={AddPeople}
                      onChange={(event) => {
                        form.setFieldValue(field.name, event.target.value);
                        setSelectedRole(event.target.value);
                      }}
                      name="role"
                    />
                  </div>
                )}
              </Field> */}
              <ErrorMessage
                name="role"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />

              <Field name="department">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Designation*"
                    variant="outlined"
                    placeholder="Enter department"
                    size="small"
                    fullWidth
                    className="mt-2"
                  // focused

                  />
                )}
              </Field>
              <ErrorMessage
                name="department"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                className="mt-3 register-btn"
              >
                {!isSubmitting ? "Continue" : "Loading..."}
              </Button>
            </Form>
          )}
        </Formik>
        <div className="text-center mt-2">
          Already have an account?
          <NavLink to="/login" className="ms-2 login-now-text">
            Login now
          </NavLink>
        </div>
      </Box>
    </Box>
  );
};

export default Register;
